import React, { Component } from "react";
import { connect } from "react-redux";

import { forceLogout } from "../redux/actions/users";

import { withRouter } from "react-router-dom";
// NavLink,

class HeaderGeneral extends Component {
  constructor(props) {
    super(props);
    this.state = {
      err: null,
    };
    // this.onLogout = this.onLogout.bind(this);
  }

  // onLogout(params) {
  //   if (this.props.forceLogout().status === 200) {
  //     this.props.history.push("/");
  //   }
  // }

  //   componentDidMount() {
  //     console.log("headerGeneral mounted");
  //   }

  render() {
    return (
      <div className="p-5 flex justify-center text-black bg-white border-b-slate-200 border-b-1">
        <div className="logo-wrapper select-none text-center">Tokotype</div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    forceLogout: (data) => dispatch(forceLogout(data)),
    //logout: data => dispatch(logout(data))
  };
}

const mapStateToProps = (state) => ({
  ...state,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HeaderGeneral));
