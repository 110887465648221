import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import queryString from "query-string";

// import { login } from "../redux/actions/users";
// checkLogin

import { checkVoucher, downloadLink } from "../redux/actions/vouchers";

class Download extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      err: null,
    };

    this.downloadFiles = this.downloadFiles.bind(this);
  }

  downloadFiles(tkn) {
    this.props
      .downloadLink({
        token: tkn,
      })
      .then((response) => {
        this.setState({ isLoading: true });
        alert("Congratulations! File is automatically downloaded");
        // console.log(response);

        // alert("Files downloaded successfully");
        // const type = response.headers["content-type"];
        // const blob = new Blob([response.data], { type: "application/zip", encoding: "UTF-8" });
        // const link = document.createElement("a");
        // link.href = window.URL.createObjectURL(blob);
        // link.download = "tokotype-file.zip";
        // link.click();
      })
      .catch((err) => {
        console.log(err);
        alert("Error downloading files");
      });
  }

  componentDidMount() {
    const qr = queryString.parse(this.props.location.search);
    const parsed = queryString.parse(atob(qr.link));
    this.downloadFiles(parsed.token);

    // console.log(parsed.email);
    // console.log(parsed.voucher);
    // console.log(parsed.token);
    // console.log(JSON.parse(parsed.email.toLowerCase()));
    // console.log(parsed.voucher);
  }

  render() {
    return (
      <div className="h-screen">
        <div className="relative flex flex-col justify-center align-middle overflow-hidden bg-white py-6 sm:py-12 h-full">
          <div className="flex flex-1 flex-col justify-center px-6 py-12 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-sm">
              <div className="mt-10 text-center text-4xl font-bold leading-9 tracking-tight text-gray-900">Congratulations!</div>
            </div>
            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm text-center">Downloading Files...</div>
          </div>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    checkVoucher: (userData) => dispatch(checkVoucher(userData)),
    downloadLink: (userData) => dispatch(downloadLink(userData)),
  };
}

const mapStateToProps = (state) => ({
  ...state,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Download));
