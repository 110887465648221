import React, { Component } from "react";
import { connect } from "react-redux";
import { NavLink, withRouter } from "react-router-dom";
// Redirect

import { login } from "../redux/actions/users";
// checkLogin

import { checkVoucher } from "../redux/actions/vouchers";

class Termsandconditions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      voucher: "",
      from: "/dashboard/home",
      isLoading: false,
      err: null,
    };
    // this.onSubmit = this.onSubmit.bind(this);
    // this.handleChange = this.handleChange.bind(this);
  }

  // handleChange(event) {
  //   this.setState({ [event.target.id]: event.target.value });
  // }

  // onSubmit(event) {
  //   this.setState({ loaded: false, isLoading: true, err: null });
  //   event.preventDefault();

  //   const { voucher } = this.state;
  //   this.props.checkVoucher({ voucher }).then((res) => {
  //     console.log(res);
  //     console.log(res.data);
  //     if (res.code !== 200) {
  //       this.setState({ isLoading: false, err: res.message });
  //     } else {
  //       // alert("Congratulation, Voucher is valid!");
  //       window.location.href = "./register-license?success=TRUE&voucher=" + btoa(voucher);
  //     }
  //   });

  //   // console.log("this.state.from");
  //   // console.log(this.state.from);

  //   // this.props.login({ email, password }).then((res) => {
  //   //   console.log(res.data);

  //   //   if (res.code === 200) {
  //   //     this.setState({
  //   //       loaded: true,
  //   //       isLoading: false,
  //   //     });
  //   //     this.setState({ isLogin: true, from: this.props.location?.state?.from?.pathname });
  //   //   } else {
  //   //     console.log(res);
  //   //     this.setState({ isLoading: false, err: res.message });
  //   //   }
  //   // });
  // }

  // componentDidMount() {
  // this.props.checkLogin().then((res) => {
  //   if (res.code === 200) {
  //     this.setState({ isLogin: true, from: this.props.location?.state?.from?.pathname });
  //   } else {
  //     this.setState({ isLogin: false });
  //   }
  // });
  // }
  // email

  render() {
    const { voucher } = this.state;

    return (
      <div className="h-screen">
        <div className="relative flex flex-col justify-center align-middle overflow-hidden bg-white py-6 sm:py-12 h-full">
          <div className="flex flex-1 flex-col justify-center px-6 py-12 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-sm">
              <div className="mt-10 text-center text-4xl font-bold leading-9 tracking-tight text-gray-900">Redeem Voucher</div>
            </div>

            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm text-center">Terms and Conditions</div>
          </div>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    login: (userData) => dispatch(login(userData)),
    checkVoucher: (userData) => dispatch(checkVoucher(userData)),
    // checkLogin: (data) => dispatch(checkLogin(data)),
  };
}

const mapStateToProps = (state) => ({
  ...state,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Termsandconditions));
