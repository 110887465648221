import React, { Component } from "react";
import { connect } from "react-redux";

import { Switch, TextField, FormControlLabel } from "@mui/material";

// import { createNews, getNewsCategories } from "../../../redux/actions/news";
import { createItem } from "../../../redux/actions/vouchers";

import Files from "react-files";

import { withRouter } from "react-router-dom";

class ItemAdd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: true,
      file: undefined,
      imageUrl: undefined,
      data: {
        slug: undefined,
        name: undefined,
        description: undefined,
        is_active: true,
      },
      err: null,
    };

    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleFileError = this.handleFileError.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleFileChange = (files) => {
    console.log("handleFileChange");
    console.log(files);

    this.setState({
      file: files[0],
      data: {
        ...this.state.data,
        slug: files[0].name.replaceAll(" ", "-").toLowerCase().trim(),
      },
      // imageUrl: files[0].preview.url,
    });
  };

  handleFileError = (error, file) => {
    console.log("error code " + error.code + ": " + error.message);
  };

  handleChange(e) {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.name === "slug" ? e.target.value.replaceAll(" ", "-").toLowerCase().trim() : e.target.value,
      },
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    console.log(this.state.data);
    console.log(this.state.file);
    this.props
      .createItem({
        slug: this.state.data.slug,
        name: this.state.data.name,
        description: this.state.data.description,
        is_active: this.state.data.is_active,
        file: this.state.file !== null ? this.state.file : undefined,
      })
      .then((res) => {
        if (res.code === 200) {
          window.location.reload(false);
          alert("Create Success!");
        } else {
          alert("Something Error");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  // componentDidMount() {
  // this.props
  //   .getNewsCategories()
  //   .then((res) => {
  //     console.log(res);
  //     this.setState({
  //       dataCategories: res.data,
  //       loaded: true,
  //     });
  //   })
  //   .catch((err) => {
  //     console.log(err);
  //   });
  // }

  render() {
    if (!this.state.loaded) {
      return <div>Plase wait...</div>;
    }

    return (
      <div className="panel home-panel">
        <div className="flex justify-between items-center p-3 bg-blue-900 text-white rounded-md mb-2">
          <span className="text-lg font-semibold">Add Item</span>
        </div>
        <form className="w-full flex flex-col gap-3 pt-2" onSubmit={this.handleSubmit}>
          <div className="w-1/2">
            <Files
              required
              className="files-dropzone"
              onChange={this.handleFileChange}
              onError={this.handleFileError}
              accepts={["font/*", ".ttf", ".otf", ".woff", ".woff2"]}
              // accepts={["image/*"]}
              // accepts={["image/png", ".pdf", "audio/*"]}
              multiple={false}
              maxFileSize={10000000}
              minFileSize={0}
              clickable={true}
            >
              {/* {this.state.image ? (
                <img
                  src={this.state.imageUrl ? this.state.imageUrl : ""}
                  alt={"thumbnail-" + this.state.data.name}
                  className="cursor-pointer news-item-thumbnail w-full hover:grayscale-0 "
                />
              ) : (
                ""
              )} */}
              <div
                className={
                  "cursor-pointer text-center block w-full p-2 text-sm border border-zinc-300 " +
                  (this.state.file === undefined ? "rounded flex align-middle justify-center items-center" : "")
                }
              >
                {this.state.file === undefined ? "Drop file here or click to upload" : this.state.file.name}
              </div>
            </Files>
          </div>

          <TextField required disabled id="slug" name="slug" type="text" label="Slug" value={this.state.data.slug || ""} />
          <TextField required id="name" name="name" type="text" label="Name" value={this.state.data.name || ""} onChange={this.handleChange} />
          <TextField
            id="description"
            name="description"
            type="text"
            label="Description"
            multiline
            rows={2}
            value={this.state.data.description}
            onChange={this.handleChange}
          />

          <FormControlLabel
            control={<Switch checked={this.state.data.is_active} />}
            label={<span style={{ fontSize: "14px" }}>Active</span>}
            name="is_active"
            onChange={() => {
              this.setState({
                data: {
                  ...this.state.data,
                  is_active: !this.state.data.is_active,
                },
              });
            }}
            className="w-fit"
          />

          <div className="flex-0">
            <input
              type="submit"
              className="text-sm w-fit p-2 bg-blue-800 text-white rounded cursor-pointer hover:bg-blue-900 min-w-16 text-center"
              label="publish"
            />
          </div>
        </form>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    createItem: (data) => dispatch(createItem(data)),
  };
}

const mapStateToProps = (state) => ({
  ...state,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ItemAdd));
