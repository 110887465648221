import axios from "axios";
import { BASE_URL, BASE_VERSION } from "../config";

export const downloadLink = (payload) => {
  return (dispatch) => {
    const data = new FormData();
    if (payload.token !== null) {
      data.append("token", payload.token);
    }

    return axios
      .post(BASE_URL + BASE_VERSION + "voucher/link", data, {
        responseType: "arraybuffer",
        headers: {
          "Content-Type": "application/zip",
        },
        // validateStatus: () => true,
      })
      .then(function (response) {
        const type = response.headers["content-type"];
        const blob = new Blob([response.data], { type: type, encoding: "UTF-8" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "tokotype-file.zip";
        link.click();

        payload = response.data;
        return payload;
      })
      .catch(function (error) {
        payload.error = error;
        return payload;
      });
  };
};
export const registerLicense = (payload) => {
  return (dispatch) => {
    // let tkk = "Bearer " + window.localStorage.getItem("app_tt");

    const data = new FormData();
    if (payload.name !== null) {
      data.append("licensee_name", payload.name);
    }
    if (payload.email !== null) {
      data.append("email", payload.email);
    }
    if (payload.c_email !== null) {
      data.append("c_email", payload.c_email);
    }
    if (payload.phone !== null) {
      data.append("phone_number", payload.phone);
    }
    if (payload.code !== null) {
      data.append("code", payload.code);
    }
    if (payload.isAgree === true) {
      data.append("is_agree", payload.isAgree);
    }

    return axios
      .post(BASE_URL + BASE_VERSION + "voucher/register", data, {
        validateStatus: () => true,
        // headers: { Authorization: tkk },
      })
      .then(function (response) {
        payload = response.data;
        return payload;
      })
      .catch(function (error) {
        payload.error = error;
        return payload;
      });
  };
};
export const checkVoucher = (payload) => {
  return (dispatch) => {
    return axios
      .post(BASE_URL + BASE_VERSION + "voucher/check", {
        code: payload.voucher,
      })
      .then(function (response) {
        payload = response.data;
        return payload;
      })
      .catch(function (error) {
        payload.error = error;
        return payload;
      });
  };
};

export const getRedeems = (payload) => {
  return (dispatch) => {
    let tkk = "Bearer " + window.localStorage.getItem("app_tt");
    return axios
      .get(BASE_URL + BASE_VERSION + "admin/redeem/all", {
        headers: { Authorization: tkk },
        validateStatus: () => true,
      })
      .then(function (response) {
        payload = response.data;
        return payload;
      })
      .catch(function (error) {
        payload.error = error;
        return payload;
      });
  };
};

export const createItem = (payload) => {
  return (dispatch) => {
    let tkk = "Bearer " + window.localStorage.getItem("app_tt");

    const data = new FormData();
    if (payload.file) {
      data.append("files", payload.file);
    }
    data.append("slug", payload.slug);
    data.append("name", payload.name);
    if (payload.description) {
      data.append("description)", payload.description);
    }
    data.append("is_active", payload.is_active);

    return axios
      .post(BASE_URL + BASE_VERSION + "admin/item", data, {
        headers: { Authorization: tkk },
        validateStatus: () => true,
      })
      .then(function (response) {
        payload = response.data;
        return payload;
      })
      .catch(function (error) {
        payload.error = error;
        return payload;
      });
  };
};
export const getItems = (payload) => {
  return (dispatch) => {
    let tkk = "Bearer " + window.localStorage.getItem("app_tt");
    return axios
      .get(BASE_URL + BASE_VERSION + "admin/item/all", {
        headers: { Authorization: tkk },
        validateStatus: () => true,
      })
      .then(function (response) {
        payload = response.data;
        return payload;
      })
      .catch(function (error) {
        payload.error = error;
        return payload;
      });
  };
};
export const getItem = (payload) => {
  return (dispatch) => {
    let tkk = "Bearer " + window.localStorage.getItem("app_tt");
    return axios
      .get(BASE_URL + BASE_VERSION + "admin/item/" + payload.id, {
        headers: { Authorization: tkk },
        validateStatus: () => true,
      })
      .then(function (response) {
        payload = response.data;
        return payload;
      })
      .catch(function (error) {
        payload.error = error;
        return payload;
      });
  };
};
export const deleteItem = (payload) => {
  return (dispatch) => {
    let tkk = "Bearer " + window.localStorage.getItem("app_tt");
    return axios
      .delete(BASE_URL + BASE_VERSION + "admin/item/" + payload.id, {
        headers: { Authorization: tkk },
        validateStatus: () => true,
      })
      .then(function (response) {
        payload = response.data;
        return payload;
      })
      .catch(function (error) {
        payload.error = error;
        return payload;
      });
  };
};

export const createVoucher = (payload) => {
  return (dispatch) => {
    let tkk = "Bearer " + window.localStorage.getItem("app_tt");

    const data = new FormData();
    if (payload.file) {
      data.append("files", payload.file);
    }
    data.append("code", payload.code);
    data.append("name", payload.name);
    if (payload.description) {
      data.append("description)", payload.description);
    }
    data.append("is_active", payload.is_active);

    if (payload.items) {
      payload.items.forEach((item) => {
        data.append("items[]", item.id);
      });
    }
    // data.append("items[]", 1);
    // data.append("items[]", 2);
    // data.append("items[]", 3);

    return axios
      .post(BASE_URL + BASE_VERSION + "admin/voucher", data, {
        headers: { Authorization: tkk },
        validateStatus: () => true,
      })
      .then(function (response) {
        payload = response.data;
        return payload;
      })
      .catch(function (error) {
        payload.error = error;
        return payload;
      });
  };
};
export const getVouchers = (payload) => {
  return (dispatch) => {
    let tkk = "Bearer " + window.localStorage.getItem("app_tt");
    return axios
      .get(BASE_URL + BASE_VERSION + "admin/voucher/all", {
        headers: { Authorization: tkk },
        validateStatus: () => true,
      })
      .then(function (response) {
        payload = response.data;
        return payload;
      })
      .catch(function (error) {
        payload.error = error;
        return payload;
      });
  };
};
export const getVoucher = (payload) => {
  return (dispatch) => {
    let tkk = "Bearer " + window.localStorage.getItem("app_tt");
    return axios
      .get(BASE_URL + BASE_VERSION + "admin/voucher/" + payload.id, {
        headers: { Authorization: tkk },
        validateStatus: () => true,
      })
      .then(function (response) {
        payload = response.data;
        return payload;
      })
      .catch(function (error) {
        payload.error = error;
        return payload;
      });
  };
};
export const deleteVoucher = (payload) => {
  return (dispatch) => {
    let tkk = "Bearer " + window.localStorage.getItem("app_tt");
    return axios
      .delete(BASE_URL + BASE_VERSION + "admin/voucher/" + payload.id, {
        headers: { Authorization: tkk },
        validateStatus: () => true,
      })
      .then(function (response) {
        payload = response.data;
        return payload;
      })
      .catch(function (error) {
        payload.error = error;
        return payload;
      });
  };
};
