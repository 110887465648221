import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { checkLogin } from "./redux/actions/users";

import Header from "./components/header";
// import Footer from "./components/footer";

import Login from "./pages/login";
import Dashboard from "./pages/dashboard";

import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
// NavLink,

//import logo from './logo.svg';
import "./input.css";
import "./output.css";
import "./index.css";
import "./App.css";
import HeaderGeneral from "./components/header-general";
import Home from "./pages/home";
import About from "./pages/about";
import Download from "./pages/download";
import Registerlicense from "./pages/registerlicense";
import Termsandconditions from "./pages/termsandconditions";
import Privacypolicy from "./pages/privacypolicy";
import Notfound from "./pages/404";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLogin: false,
      isLoaded: false,
      err: {
        message: "",
        code: 0,
      },
    };
  }

  // componentDidMount() {
  // console.log("APP.JS");
  // console.log(this.props);
  // if (window.localStorage.getItem("app_tt") !== null) {
  //   this.props.checkLogin().then((res) => {
  //     if (res.code === 200) {
  //       //console.log(this.props.isLogin);
  //       console.log("checkLogin");
  //       console.log(res);
  //       // ?.state?.from?.pathname
  //       // console.log(location);
  //     }
  //   });
  // }
  // }

  render() {
    return (
      <div className="min-h-screen">
        <Router basename="/">
          <>
            <Switch>
              <PrivateRoute loginState={this.props.isLogin} path="/dashboard">
                <Header />
                <Dashboard></Dashboard>
              </PrivateRoute>
              <Route exact path="/terms-conditions">
                <HeaderGeneral />
                <Termsandconditions />
              </Route>
              <Route exact path="/privacy-policy">
                <HeaderGeneral />
                <Privacypolicy />
              </Route>
              <Route exact path="/register-license">
                <HeaderGeneral />
                <Registerlicense />
              </Route>
              <Route exact path="/download-files">
                <HeaderGeneral />
                <Download />
              </Route>
              <Route exact path="/about">
                <HeaderGeneral />
                <About />
              </Route>
              <Route exact path="/login">
                <Login></Login>
              </Route>
              <Route exact path="/">
                <HeaderGeneral />
                <Home />
                {/* <>Welcome...</> */}
                {/* <NavLink className="href-link" to="./terms-conditions">
                  Terms and Conditions
                </NavLink>
                <NavLink className="href-link" to="./privacy-policy">
                  Privacy Policy
                </NavLink> */}
                {/* <Redirect to="/dashboard"></Redirect> */}
              </Route>
              <Route path="*">
                <HeaderGeneral />
                <Notfound />
              </Route>
            </Switch>
          </>
          {/* <Footer /> */}
        </Router>
      </div>
    );
  }
}

function PrivateRoute({ children, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        rest.loginState !== false ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

const mapStateToProps = (state) => ({
  ...state,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      checkLogin,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(App);

// npx tailwindcss -i ./src/input.css -o ./src/output.css --watch
// npx tailwindcss -i ./src/input.css -o ./src/output.css --watch
